<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="aq-list" style="min-height: 100vh;" ref="listView">
			<div class="aq-item" v-for="(item, index) in dataList" :key="index">
				<div class="question-item">
					<div class="question-item-head">
						<img src="../../../assets/imgs/mobile/img_yonghu@2x.png" alt="" class="question-avatar">
						<div class="question-item-head-r">
							<div class="question-user-info">
								<span class="gender" v-text="item.gender == 1 ? '男' : '女'"></span>
								<span class="age" v-text="item.age + '歲'"></span>
								<span class="add-time" v-text="item.createdtime"></span>
							</div>
							<h3 class="question-item-title">
								<router-link :to="'/ask/list?IllCate='+item.illnesscategoryid" class="q-category" v-text="'#'+item.illnesscategorycode"></router-link>
								<router-link :to="'/ask/detail/'+item.askid" v-text="item.title"></router-link>
							</h3>
						</div>
					</div>
					<p class="question-item-content" v-text="'問題描述:  '+item.note"></p>
				</div>
				<div :class="{'answer-list-w': true, 'hide': askidList.indexOf(item.askid) == -1}">
					<div class="answer-list">
						<div class="answer-item" v-for="(answer, i) in item.answerList" :key="i">
							<img src="../../../assets/imgs/mobile/img_nvyis@2x.png" alt="" class="answer-avatar">
							<div class="answer-item-r">
								<div class="answer-item-head">
									醫師
									<span class="doctor-name" v-text="answer.doctorname"></span>
									<span class="create-time" v-text="answer.createdtime"></span>
								</div>
								<p class="answer-item-content" v-text="answer.note"></p>
							</div>
						</div>
					</div>
				</div>
				<div class="aq-operate">
					<router-link class="btn-sea-all" :to="`/ask/detail/${item.askid}`">{{askidList.indexOf(item.askid) == -1 ? (`${!item.answerList ? 0 : item.answerList.length}條回復`) : '收起回复'}}</router-link>
					<div class="btn-save-aq">收藏<span>+1</span></div>
				</div>
			</div>
			<div class="load-more" v-if="isLoadMore">
				<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
				<span class="text">正在加载...</span>
			</div>
			<div class="load-finished" v-if="isLoadFinished">已全部加載</div>
		</div>
		<loading v-if="loading"/>
	</van-pull-refresh>
</template>

<script>
	import ask_list from '@/views/mixins/ask_list'
	import Loading from '@/layouts/Loading.vue'
	export default {
		mixins: [
			ask_list,
		],
		components: {
			Loading
		},
		data() {
			return {
				loading: true,
				isRefresh: false,
				isLoadMore: false,
				isLoadFinished: false,
				pageNo: 1,
				pageSize: 10,
				maxPage: 1,
				clientId: this.$store.getters.clientId,
				dataList: [],
				loadMore: false,
                askidList: [],
                keyword: null,
                categoryid: null, 
			}
		},
		mounted: function(){
            var hash = this.$route.hash
            hash = hash.replace('#', '')
            var list = hash.split('&')
            var key = list[0].split('=')[0]
            var value = list[0].split('=')[1] / 1
            if(key == 'page') {
                if(! isNaN(value)) {
                    this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                }
            }
            if(this.$route.query.IllCate) {
                this.categoryid = this.$route.query.IllCate
            }
            if(this.$route.query.keyword) {
                this.keyword = this.$route.query.keyword
            }
			this.onRefresh()
		},
		methods: {
			onRefresh() {
				this.pageNo = 1
				this.loading = true
				this.isLoadMore = false
				this.isLoadFinished = false
				this.loadData({
                    pageNo: this.pageNo, 
                    pageSize: this.pageSize, 
                    keyword: this.keyword, 
                    categoryid: this.categoryid 
                }, (data, error)=>{
					this.loading = false
					this.isRefresh = false
					this.dataList = (data && data.list) || []
					var total = (data && data.total) || 0
					if(total > 0) {
						this.maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
						if(this.pageNo >= this.maxPage) {
							this.isLoadFinished = true
						}
					} else {
						if(!error) {
							this.isLoadFinished = true
						}
					}
				})
			},
			extrendAnswer(askData){
                if(! askData.answerList.length) return
                if(this.askidList.indexOf(askData.askid) == -1) {
                    this.askidList.push(askData.askid)
                } else {
                    this.askidList.splice(this.askidList.indexOf(askData.askid), 1)
                }
			},
			handleScroll (event) {
				if(this.$refs.listView.clientHeight < window.innerHeight) return
				if((window.innerHeight + window.pageYOffset) > this.$refs.listView.clientHeight) {
					// 加载更多
					if(!this.loading && !this.isLoadMore && this.pageNo < this.maxPage) {
						this.isLoadMore = true
						this.loading = true
						this.loadData({
                            pageNo: this.pageNo, 
                            pageSize: this.pageSize, 
                            keyword: this.keyword, 
                            categoryid: this.categoryid 
                        }, (data, error)=>{
							this.loading = false
							this.isLoadMore = false
							this.dataList = this.dataList.concat((data && data.list) || [])
							if(!error) {
								this.pageNo = this.pageNo + 1
								var total = (data && data.total) || 0
								if(total > 0) {
									var maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
									if(this.pageNo >= maxPage) {
										this.isLoadFinished = true
									}
								}
							}
						})
					}
				}
			},
		},
		created () {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
        },
        watch: {
			'$route.query.keyword': function(keyword){
                this.pageNo = 1
                this.dataList = []
                this.visible = false
                this.keyword = keyword
                this.onRefresh()
            },
            '$route.query.IllCate': function(categoryid){
                this.pageNo = 1
                this.dataList = []
                this.visible = false
                this.categoryid = categoryid
                this.onRefresh()
            },
		}
	}
</script>

<style lang="scss" scoped>
	.aq-list {
		display: flex;
		flex-direction: column;
		position: relative;
        margin-top: 20px;
	}

	.question-item-content {
		width: auto;
		margin: 0px 0px;
		width: auto;
		margin-left: 0px;
		margin-top: 21px;
		-webkit-line-clamp: 2;
		color: #231F20;
		font-size: 28px;
		font-weight: 400;
	}

	.aq-list .aq-item .question-item {
		margin: 0px;
	}

	.aq-list .aq-item .question-item .question-avatar {
		width: 32px;
		height: 32px;
		display: none;
	}

	.aq-list .aq-item .question-item .question-user-info {
		flex: 1;
		display: flex;
		flex-direction: row;
		height: 23px;
		line-height: 23px;
		margin-bottom: 25px;
		width: auto;
		font-size: 24px;
		font-weight: 400;
		color: #969696;
	}

	.aq-list .aq-item .question-item .question-user-info > .gender {
		margin-left: 0px;
	}

	.aq-list .aq-item .question-item .question-user-info > .age {
		margin-left: 08px;
	}

	.aq-list .aq-item .question-item .question-user-info > .add-time {
		margin-left: 28px;
	}

	.aq-list .aq-item .question-item .question-item-head-r {
		margin-top: 04px;
	}

	.aq-list .aq-item .question-item .question-item-title  {
		font-size: 34px;
		font-weight: bold;
		color: #231F20;
	}
	
	.aq-list .aq-item .question-item .question-item-title > .q-category {
		color: #FF8000;
		margin-right: 8px;
	}

	.aq-list .answer-item .answer-avatar {
		width: 32px;
		height: 32px;
		display: flex;
		flex-direction: row;
		margin-right: 18px;
	}

	.aq-list .aq-operate {
		margin: 50px 0px 0px 0px;
		display: flex;
		flex-direction: row;
		/* border-bottom: 1px solid #E8E8E8; */
		padding-bottom: 32px;
		position: relative;
	}

	.aq-list.my-aq .aq-operate {
		margin-top: 47px;
	}

	.aq-list .aq-item:last-child .aq-operate {
		border-bottom: 0;
	}

	.aq-list .aq-operate .btn-sea-all {
		height: 25px;
		line-height: 25px;
		position: relative;
		padding-left: 48px;
		padding-right: 0px;
		font-weight: 400;
		color: #231F20;
		font-size: 26px;
		background-color: transparent;
		border-radius: 0px;
		display: inline-block;
		cursor: pointer;
		margin-right: 0px;
	}

	.aq-list .aq-operate .btn-sea-all::after {
		content: '';
		position: absolute;
		left: 0px;
		top: 0px;
		width: 25px;
		height: 23px;
		background-image: url(../../../assets/imgs/mobile/img_xiaoxi@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.aq-list .aq-operate .btn-sea-all.extrend {
		padding-left: 0;
		color: transparent;
		margin-right: 126px;
	}

	.aq-list .aq-operate .btn-sea-all.extrend::after {
		content: '收起回復';
		position: absolute;
		left: 17px;
		top: 0px;
		width: 105px;
		height: auto;
		font-size: 26px;
		line-height: 25px;
		color: #36C4D0;
		font-weight: bold;
		background-image: none;
	}

	.aq-list .aq-operate .btn-save-aq {
		height: 25px;
		position: relative;
		margin-left: 92px;
		line-height: 25px;
		padding-left: 40px;
		padding-right: 15px;
		font-weight: 400;
		color: #231F20;
		font-size: 26px;
		cursor: pointer;

		display: none;
	}

	.aq-list .aq-operate .btn-save-aq > span {
		position: absolute;
		font-weight: bold;
		color: #FF8000;
		font-size: 32px;
		opacity: 0;
		right: -30px;
		transform: translateY(100%);
		transition: all .5s ease-in-out;
	}

	.aq-list .aq-operate .btn-save-aq.saved {
		position: absolute;
		height: 25px;
		left: 2.57px;
		top: 0px;
		height: 25px;
		line-height: 25px;
		padding-left: 40px;
		padding-right: 15px;
		font-weight: 400;
		color: #231F20;
		font-size: 26px;
		cursor: pointer;
	}

	.aq-list .aq-operate .btn-save-aq::after {
		content: '';
		position: absolute;
		left: 0px;
		top: 0px;
		width: 25px;
		height: 21px;
		background-image: url(../../../assets/imgs/mobile/icon_shouca@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.aq-list .aq-operate .btn-save-aq.saved::after {
		background-image: url(../../../assets/imgs/mobile/icon_ysc@2x.png);
	}

	.aq-list .answer-list-w .answer-list {
		background-color: #EFF4F9;
		padding: 0px 19px 30px 19px;
	}

	.aq-list .answer-item {
		display: flex;
		flex-direction: row;
		margin-top: 30px;
	}

	.aq-list .answer-item .answer-item-r .answer-item-head {
		flex: 1;
		font-weight: 400;
		color: #969696;
		font-size: 24px;
		line-height: 23px;
	}

	.aq-list .answer-item .answer-item-r .answer-item-content {
		flex: 1;
		font-weight: 400;
		font-size: 28px;
		color: #231F20;
		line-height: 28px;
		margin-top: 15px;
	}

	.aq-list .aq-item {
		padding: 31px 32px 0px 32px;
		border-bottom: 0;
		margin: 0px 24px 16px 24px;
		background-color: #fff;
	}

	.aq-list .aq-operate .btn-sea-all.extrend::before {
		display: none;
	}

	.aq-list .answer-list-w {
		margin-top: 33px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		margin-top: 30px;
		max-height: unset;
		transition: all .5s cubic-bezier(0, 1, 5, 1);
	}

	.aq-list .answer-list-w.hide {
		margin-top: 0px;
		max-height: 0px;
	}

	.aq-list .aq-operate .btn-unshift-q {
		position: absolute;
		top: 0px;
		left: 2.32px;
		height: 25px;
		line-height: 25px;
		padding-left: 40px;
		padding-right: 0px;
		font-weight: 400;
		color: #231F20;
		font-size: 26px;
		background-color: transparent;
		border-radius: 0px;
		display: inline-block;
		cursor: pointer;
		margin-right: 0px;
	}

	.aq-list .aq-operate .btn-unshift-q::after {
		content: '';
		position: absolute;
		left: 0px;
		top: -02px;
		width: 24px;
		height: 22px;
		background-image: url(../../../assets/imgs/mobile/icon_zhuiwen@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.aq-list .aq-operate .btn-unshift-q.disable::after {
		background-image: url(../../../assets/imgs/mobile/img_zhuiwenblock@2x.png);
	}

	.aq-list.my-aq .aq-item .question-item .question-item-content {
		margin-top: 0px;
	}

	.sub-q {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		margin-top: 21px;
	}

	.sub-q .sub-q-hint,
	.sub-q > .sub-q-title {
		font-size: 28px;
		line-height: 40px;
		color: #1B2122;
		font-weight: bold;
	}

	.sub-q > .sub-q-title {
		text-overflow: ellipsis;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		margin-left: 0px;
	}

	.sub-q .sub-q-hint {
		padding-left: 0px;
		color: #36C4D0;
		display: inline-block;
		margin-right: 08px;
	}

	.sub-q .sub-q-hint::after {
		display: none;
	}

	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}

	// loadmore
	.load-more {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.load-more > .text {
		margin-left: 24px;
	}

	.lds-default {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-default div {
		position: absolute;
		width: 6px;
		height: 6px;
		background: #36C4D0;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
	}
	.lds-default div:nth-child(1) {
		animation-delay: 0s;
		top: 37px;
		left: 66px;
	}
	.lds-default div:nth-child(2) {
		animation-delay: -0.1s;
		top: 22px;
		left: 62px;
	}
	.lds-default div:nth-child(3) {
		animation-delay: -0.2s;
		top: 11px;
		left: 52px;
	}
	.lds-default div:nth-child(4) {
		animation-delay: -0.3s;
		top: 7px;
		left: 37px;
	}
	.lds-default div:nth-child(5) {
		animation-delay: -0.4s;
		top: 11px;
		left: 22px;
	}
	.lds-default div:nth-child(6) {
		animation-delay: -0.5s;
		top: 22px;
		left: 11px;
	}
	.lds-default div:nth-child(7) {
		animation-delay: -0.6s;
		top: 37px;
		left: 7px;
	}
	.lds-default div:nth-child(8) {
		animation-delay: -0.7s;
		top: 52px;
		left: 11px;
	}
	.lds-default div:nth-child(9) {
		animation-delay: -0.8s;
		top: 62px;
		left: 22px;
	}
	.lds-default div:nth-child(10) {
		animation-delay: -0.9s;
		top: 66px;
		left: 37px;
	}
	.lds-default div:nth-child(11) {
		animation-delay: -1s;
		top: 62px;
		left: 52px;
	}
	.lds-default div:nth-child(12) {
		animation-delay: -1.1s;
		top: 52px;
		left: 62px;
	}
	@keyframes lds-default {
		0%, 20%, 80%, 100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.5);
		}
	}
</style>